<template>
    <properties-block v-bind="$props" v-on="$listeners">
        <template #layout>
            <b-form-group label-cols="4">
                <template #label>
                    <span v-tr>Spacing|Espacement</span>
                    <btn-responsive-prop v-model="block" field="spacing"/>
                </template>
                <b-select v-model="block.mediaProxy.spacing">
                    <option value="0" v-tr>None|Sans</option>
                    <option v-for="n in 100" :key="`width-${n}`" :value="`${n}px`">{{n}}px</option>
                </b-select>
            </b-form-group>
        </template>
    </properties-block>
</template>

<script>
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";

// properties-block-inline #hot-reload-debug
export default {
    name: `properties-block-inline`,
    components: {PropertiesBlock},
    extends: PropertiesBlock
}
</script>

<style lang="scss" scoped>
</style>
